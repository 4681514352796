const appName = process.env.REACT_APP_NAME
const releaseEnv = process.env.REACT_APP_RELEASE_ENV

// 生产环境使用二级域名，测试及其他环境使用三级域名
const env = releaseEnv === 'production' ? '' : '.' + releaseEnv

const configMap = {
  // 彼选店
  jpd: {
    title: '彼选店',
    apiUrl: `//jcapi${env}.yijiahaohuo.com/v1/?service=`,
    jsapiUrl: `//mpwx${env}.yijiahaohuo.com/v1/?service=`,
    imageCdn: 'cdnimg81.yijiahaohuo.com',
    videoCdn: 'cdnvideo83.yijiahaohuo.com',
    dir: 'yijiahaohuo',
    appName: '蚁家人',
    appId: 'wx2975ad8b1d81ce8c',
    company: '青岛蚁阵数据科技有限公司',
    email: 'yizhenvip@yijiahaohuo.com',
    captchaId: '2029520617',
  },
  // 彼选匠心店
  jpjx: {
    title: '彼选匠心店',
    apiUrl: `//jcapi${env}.jiangpinjiangxin.com/v1/?service=`,
    jsapiUrl: `//mpwx${env}.jiangpinjiangxin.com/v1/?service=`,
    imageCdn: 'cdnimg81.zhihuixiadan.com',
    videoCdn: 'cdnvideo83.zhihuixiadan.com',
    dir: 'zhihuixiadan',
    appName: '质惠下单系统',
    appId: 'wxf0e23bb1b0c16289',
    company: '青岛美度美成商贸有限公司',
    email: 'zhihuixiadanvip@163.com',
    captchaId: '2068622856',
  },
  // 彼选初心店
  jpcx: {
    title: '彼选初心店',
    apiUrl: `//jcapi${env}.jiangpinchuxin.com/v1/?service=`,
    jsapiUrl: `//mpwx${env}.jiangpinchuxin.com/v1/?service=`,
    imageCdn: 'cdnimg81.pikaxiadan.com',
    videoCdn: 'cdnvideo83.pikaxiadan.com',
    dir: 'pikaxiadan',
    appName: '皮卡下单系统',
    appId: 'wxac2df4cee2b48e26',
    company: '青岛质美供应链管理有限公司',
    email: 'pikaxiadanvip@163.com',
    captchaId: '2083827195',
  },
  // 彼选工厂店
  jpgc: {
    title: '彼选工厂店',
    apiUrl: `//jcapi${env}.jiangpingongchang.com/v1/?service=`,
    jsapiUrl: `//mpwx${env}.jiangpingongchang.com/v1/?service=`,
    imageCdn: 'cdnimg81.dongshengxiadan.com',
    videoCdn: 'cdnvideo83.dongshengxiadan.com',
    dir: 'dongshengxiadan',
    appName: '东升下单系统',
    appId: 'wx9a1e93770c859eb3',
    company: '青岛质序供应链管理有限公司',
    email: 'dongshengxiadanvip@163.com',
    captchaId: '190131863',
  },
  // 彼选国货店
  jpgh: {
    title: '彼选国货店',
    apiUrl: `//jcapi${env}.jiangpinguohuo.com/v1/?service=`,
    jsapiUrl: `//mpwx${env}.jiangpinguohuo.com/v1/?service=`,
    imageCdn: 'cdnimg81.antexiadan.com',
    videoCdn: 'cdnvideo83.antexiadan.com',
    dir: 'antexiadan',
    appName: '安特下单系统',
    appId: 'wx3aac7216473913ea',
    company: '青岛匠之良品商贸有限公司',
    email: 'antexiadanvip@163.com',
    captchaId: '192336587',
  },
}

export const config = configMap[appName]
export const commonUrl = config.apiUrl
export const version = 20250401 // 八位日期，每次发布前更新
