import clsx from 'clsx'
import { UAParser } from 'ua-parser-js'

import { useTitle } from '../hooks/useTitle'
import { downloadApp, isIOS } from '../utility/utils'
import { config } from '../utility/config'
import styles from './download.module.css'
import './register/register.css'

import huawei from '../images/brand/huawei.png'
import honor from '../images/brand/honor.png'
import xiaomi from '../images/brand/xiaomi.png'
import redmi from '../images/brand/redmi.png'
import oppo from '../images/brand/oppo.png'
import oneplus from '../images/brand/oneplus.png'
import realme from '../images/brand/realme.png'
import vivo from '../images/brand/vivo.png'
import iqoo from '../images/brand/iqoo.png'
import samsung from '../images/brand/samsung.png'

const tutotials = [
  {
    name: '华为/荣耀',
    icons: [huawei, honor],
    content: [
      '关闭「纯净模式」：',
      '打开「设置」→ 顶部搜索「纯净模式」 点击进入→关闭「增强防护」→ 点击「确定」退出。',
      '设置完成后安装时如遇拦截，点击「继续安装」即可。',
    ],
  },
  {
    name: '小米/REDMI',
    icons: [xiaomi, redmi],
    content: [
      '开启「安装权限」：',
      '进入「设置」→ 点击「应用设置」→ 选择「纯净模式」→ 关闭「纯净模式」→ 返回桌面。',
      '首次安装时，点击弹窗中的「设置」→ 开启「允许本次安装」。',
    ],
  },
  {
    name: 'OPPO/一加/真我',
    icons: [oppo, oneplus, realme],
    content: [
      '关闭「安全检测」：',
      '打开「设置」→ 搜索「安全应用推荐」→ 进入对应选项 → 关闭「安全应用推荐」开关。',
      '安装时点击弹窗中的「继续安装」完成操作。',
    ],
  },
  {
    name: 'vivo/iQOO',
    icons: [vivo, iqoo],
    content: [
      '允许「未知来源」安装：',
      '进入「设置」→ 搜索「未知来源」→ 选择「允许安装未知来源应用」→ 选择需授权的应用（如浏览器或文件管理器）→ 开启权限。',
      '安装时点击「继续安装」跳过拦截提示。',
    ],
  },
  {
    name: '三星',
    icons: [samsung],
    content: [
      '解除安装限制：',
      '打开「设置」→ 进入「生物识别与安全」→ 找到「安装未知应用」→ 选择用于安装的应用（如浏览器或文件管理器）→ 开启「允许来自此来源的应用」→ 返回安装界面完成操作。',
    ],
  },
]

const parser = new UAParser()
const vendor = parser.getDevice().vendor || ''
const vendorIndexMap = {
  Xiaomi: 1,
  OPPO: 2,
  OnePlus: 2,
  Realme: 2,
  Vivo: 3,
  Samsung: 4,
}
// 将当前设备的厂商移到第一位
if (vendor in vendorIndexMap) {
  const index = vendorIndexMap[vendor as keyof typeof vendorIndexMap]
  const tutorial = tutotials[index]
  tutotials.splice(index, 1)
  tutotials.unshift(tutorial)
}

export default function Download() {
  useTitle(`下载${config.appName}APP`)

  if (isIOS) {
    return (
      <div className="reg-done-page">
        <button className="down-app" onClick={() => downloadApp(0)}>
          下载{config.appName}APP
        </button>
      </div>
    )
  }

  return (
    <div className="reg-done-page">
      <button
        className={clsx('down-app', styles.button)}
        onClick={() => downloadApp(0)}
      >
        下载{config.appName}APP
      </button>

      <div className={styles['is-android']}>如果您是安卓手机</div>
      <div>应用安装失败可尝试按以下步骤设置完后重新安装</div>

      {tutotials.map((item, index) => (
        <div key={index} className={styles.device}>
          <div className={styles.device__head}>
            <span className={styles.device__name}>{item.name}</span>
            {item.icons.map((icon, index) => (
              <img
                key={index}
                className={styles.device__icon}
                src={icon}
                alt={item.name.split('/')[index]}
              />
            ))}
          </div>
          {item.content.map((content, index) => (
            <div key={index} className={styles.device__content}>
              {content}
            </div>
          ))}
        </div>
      ))}
    </div>
  )
}
