import { Modal, Toast } from 'antd-mobile'
import { getAppLink } from '../api'
import { config } from './config'

const WECHAT_AUTH_URL = 'https://open.weixin.qq.com/connect/oauth2/authorize'

export const isWeChat = /MicroMessenger/i.test(navigator.userAgent)
export const isWxWork = /wxwork/i.test(navigator.userAgent)

// https://github.com/bendrucker/is-ios/blob/master/index.js
export const isIOS =
  typeof navigator !== 'undefined' &&
  (/iPad|iPhone|iPod/.test(navigator.userAgent || '') ||
    (navigator.platform === 'MacIntel' && navigator.maxTouchPoints > 1))

export function shopIdValidate(shopId: any) {
  const shopArr = ['5zU4n', 'null', 'undefined']
  if (!shopId) {
    return false
  } else if (shopArr.indexOf(shopId) > -1) {
    return false
  } else if (!isNaN(Number(shopId))) {
    return false
  } else if (shopId.length > 7) {
    return false
  } else {
    return true
  }
}

type FormatHotAreaHTML = {
  html: string // 热区代码 http://www.yymoban.com/tool/requ/
  parentWidth: number // 容器宽度
  hrefFormatter?: (href: string) => string // 格式化链接
}

/**
 * 根据容器大小计算热区大小及位置，替换热区链接
 */
export function formatHotAreaHTML({
  html,
  parentWidth,
  hrefFormatter = (href) => href,
}: FormatHotAreaHTML): string {
  const $codeArea = new DOMParser()
    .parseFromString(html, 'text/html')
    .getElementById('codeArea')

  if (!$codeArea) return ''

  const $areas = $codeArea.getElementsByTagName('area')

  // 热区原始宽高
  const originWidth = Number($codeArea.style.width.replace('px', ''))
  const originHeight = Number($codeArea.style.height.replace('px', ''))

  // 计算缩放比例
  const ratio = parentWidth / originWidth

  $codeArea.style.width = `${parentWidth}px`
  $codeArea.style.height = `${originHeight * ratio}px`

  for (let i = 0; i < $areas.length; i++) {
    const $area = $areas[i]
    const coords = ($area.getAttribute('coords') || '').split(',')
    const href = $area.getAttribute('href') || ''

    // 删除无用属性
    $area.removeAttribute('target')
    $area.removeAttribute('title')
    // 根据屏幕大小缩放 coords 的值
    $area.setAttribute(
      'coords',
      coords.map((v) => (Number(v) * ratio).toFixed(1)).join(',')
    )
    // 替换 schema url 为当前网页的商品详情 url
    $area.setAttribute(
      'href',
      href.indexOf('yymoban') === -1 ? hrefFormatter(href) : '/'
    )
  }

  return $codeArea.outerHTML
}

/**
 * 下载 APP
 * @param {number} isStable 0:最新版; 1:稳定版
 */
export async function downloadApp(isStable: 0 | 1) {
  const ua = navigator.userAgent

  // 在微信内
  if (ua.toLowerCase().indexOf('micromessenger') !== -1) {
    return Toast.info('请点击右上角在浏览器打开')
  }

  Toast.loading(null, 0, undefined, true)

  const appType = isIOS ? 1 : 2

  try {
    const { update_url } = await getAppLink(appType, isStable)

    Toast.hide()
    window.location.href = update_url
  } catch (error) {
    console.warn(error)
    Toast.fail('下载失败，请稍后重试')
  }
}

/**
 * 判断大陆手机号（有短信功能）
 * @param {string} phoneNumber 手机号
 * @see https://github.com/VincentSit/ChinaMobilePhoneNumberRegex/tree/2da699d
 */
export function isChinaMainlandPhoneNumber(phoneNumber: string) {
  const re =
    /^(?:\+?86)?1(?:3\d{3}|5[^4\D]\d{2}|8\d{3}|7(?:[0-35-9]\d{2}|4(?:0\d|1[0-2]|9\d))|9[0-35-9]\d{2}|6[2567]\d{2}|4[579]\d{2})\d{6}$/
  return re.test(phoneNumber)
}

/**
 * 设置合法的店铺 ID
 */
export function setValidShopId(shopId?: string) {
  if (typeof shopId === 'string' && shopId.length === 32) {
    localStorage.setItem('shop_id', shopId)
  }
}

/**
 * 重新登录
 * @param tokenKey token 存储的 key
 * @param forceLogin 是否强制登录
 */
export function relogin({ tokenKey = '', forceLogin = false } = {}) {
  if (tokenKey) {
    localStorage.removeItem(tokenKey)
  }

  if (isWeChat && !isWxWork) {
    const appId = localStorage.getItem('appId')
    const url = new URL(window.location.href)

    if (forceLogin) {
      url.searchParams.set('force_login', '1')
    }

    const redirectUri = encodeURIComponent(url.href)

    window.location.replace(
      `${WECHAT_AUTH_URL}?appid=${appId}&redirect_uri=${redirectUri}&response_type=code&scope=snsapi_userinfo`
    )
  }
}

let isModalOpen = false

/**
 * 显示强制登录弹窗
 * @param content 弹窗内容
 */
export function showForceLoginModal(content: string) {
  if (!isModalOpen) {
    isModalOpen = true
    Modal.alert(null, content, [
      {
        text: '确定',
        onPress: () => {
          relogin({ tokenKey: 'c_token', forceLogin: true })
          isModalOpen = false
        },
      },
    ])
  }
}

export type CreateCaptchaOptions = {
  showCaptcha?: boolean
  captchaId: string
}
export type TencentCaptchaCallbackProps = {
  ret: number
  randstr: string
  ticket: string
  errorCode?: number
  errorMessage?: string
}

/**
 * 创建验证码滑块
 * @see https://cloud.tencent.com/document/product/1110/36841 errorCode
 * @see https://cloud.tencent.com/document/product/1110/36926 CaptchaCode
 */
export function createCaptcha(
  options: CreateCaptchaOptions
): Promise<TencentCaptchaCallbackProps> {
  const { showCaptcha = true, captchaId } = options

  if (!showCaptcha) {
    const timestamp = Math.floor(new Date().getTime() / 1000)
    const ticket = 'terror_4004_' + captchaId + '_' + timestamp

    return Promise.resolve({
      ret: 0,
      randstr: '@' + Math.random().toString(36).substring(2),
      ticket,
      errorCode: 4004,
      errorMessage: 'skip_error',
    })
  }

  return new Promise((resolve, reject) => {
    try {
      const captcha = new TencentCaptcha(
        captchaId,
        (res: TencentCaptchaCallbackProps) => {
          if (res.ret === 0) {
            resolve(res)
          } else {
            reject(res)
          }
        }
      )
      captcha.show()
    } catch (err) {
      const timestamp = Math.floor(new Date().getTime() / 1000)
      const ticket = 'terror_1001_' + captchaId + '_' + timestamp

      resolve({
        ret: 0,
        randstr: '@' + Math.random().toString(36).substring(2),
        ticket,
        errorCode: 1001,
        errorMessage: 'jsload_error',
      })
    }
  })
}

/**
 * 获取YYYY-MM-DD格式的日期
 * @param {Date} date 日期
 * @returns {string} YYYY-MM-DD格式的日期
 */
export function getYYYYMMDD(date: Date): string {
  const year = date.getFullYear()
  const month = date.getMonth() + 1
  const day = date.getDate()

  return `${year}-${month < 10 ? '0' + month : month}-${
    day < 10 ? '0' + day : day
  }`
}

/**
 * 创建一个文件选择对话框，让用户选择文件
 *
 * @param {Object} options - 配置选项
 * @param {string} [options.accept='image/*'] - 接受的文件类型
 * @param {boolean} [options.multiple=false] - 是否允许选择多个文件
 * @returns {Promise<FileList>} 当用户选择了文件后，返回一个包含所选文件的 Promise
 */
export function selectFiles({
  accept = 'image/*',
  multiple = false,
}: {
  accept?: string
  multiple?: boolean
} = {}): Promise<FileList> {
  return new Promise((resolve, reject) => {
    const input = document.createElement('input')
    input.type = 'file'
    input.multiple = multiple
    input.accept = accept
    input.style.display = 'none'
    document.body.appendChild(input)

    input.onchange = () => {
      if (input.files && input.files.length > 0) {
        resolve(input.files)
      } else {
        reject(new Error('未选择文件'))
      }
      document.body.removeChild(input)
    }
    input.onerror = () => {
      reject(new Error('选择文件时发生错误'))
      document.body.removeChild(input)
    }
    input.click()
  })
}

/**
 * 将文件上传到七牛云
 *
 * @param {File} file - 要上传的文件
 * @param {Function} getToken - 一个函数，当调用时，返回一个包含 token 和 key 的 Promise
 * @returns {Promise<{ url: string; key: string }>} 上传成功后，返回一个包含 url 和 key 的 Promise
 */
export async function uploadFileToQiniu(
  file: File,
  getToken: () => Promise<{ token: string; key: string }>
) {
  const { token, key } = await getToken()
  const formData = new FormData()
  formData.append('token', token)
  formData.append('key', [key, file.type.split('/')[1]].join('.'))
  formData.append('file', file)
  const res = await fetch('https://upload.qiniup.com', {
    method: 'POST',
    body: formData,
  })
  if (!res.ok) {
    throw new Error('上传失败，请重试')
  }
  return { key, url: `${window.location.protocol}//${config.imageCdn}/${key}` }
}

/**
 * 加载图片
 * @param src 图片地址
 * @returns 图片元素
 */
export function loadImage(src: string): Promise<HTMLImageElement> {
  return new Promise((resolve, reject) => {
    const image = new Image()
    image.onload = () => resolve(image)
    image.onerror = reject
    image.crossOrigin = 'anonymous'
    image.src = src
  })
}

/**
 * 如果数字小于10，在前面添加一个零
 * @param num - 要填充的数字
 * @returns 填充后的数字字符串
 */
export function padZero(num: number) {
  return num < 10 ? `0${num}` : String(num)
}

/**
 * 格式化倒计时 HH:mm:ss
 * @param countdown - 倒计时秒数
 * @returns 格式化后的倒计时
 * @example
 * formatCountdown(3600) // "01:00:00"
 */
export function formatCountdown(countdown: number) {
  if (countdown <= 0) {
    return '00:00:00'
  }

  const hours = Math.floor(countdown / 3600)
  const minutes = Math.floor((countdown % 3600) / 60)
  const seconds = Math.floor(countdown % 60)

  return `${padZero(hours)}:${padZero(minutes)}:${padZero(seconds)}`
}

/**
 * 格式化倒计时 d天HH小时mm分ss秒
 * @param countdown - 倒计时秒数
 * @returns 格式化后的倒计时
 */
export function formatCountdownForHuman(countdown: number) {
  if (countdown <= 0) {
    return '0时0分0秒'
  }

  const days = Math.floor(countdown / 86400)
  const hours = Math.floor((countdown % 86400) / 3600)
  const minutes = Math.floor((countdown % 3600) / 60)
  const seconds = Math.floor(countdown % 60)

  const hmd = `${padZero(hours)}时${padZero(minutes)}分${padZero(seconds)}秒`

  return days > 0 ? `${days}天${hmd}` : hmd
}

/**
 * 提示消息
 * @description 文字数量大于某个阈值时，使用对话框
 */
export function showToast(
  content: React.ReactNode,
  duration = 1.5,
  onClose?: () => void
) {
  if (typeof content === 'string' && content.length > 24) {
    Modal.alert('', content, [{ text: '好的', onPress: onClose }])
  } else {
    Toast.info(content, duration, onClose)
  }
}
