import { useCallback, useEffect, useMemo, useState } from 'react'
import { Link, useHistory } from 'react-router-dom'
import { useTitle } from 'react-use'
import { ActivityIndicator, Icon } from 'antd-mobile'
import QueueAnim from 'rc-queue-anim'
import clsx from 'clsx'
import TabBar from '../tabbar/tabbar'
import { BackTop, Empty, GoodsCard, ShopInfo } from '../../components'
import { showConfirm, ShowSimpleContent } from '../../utility/modal'
import {
  useErrorResponse,
  useReachEnd,
  useRequest,
  useRequestInfinite,
  useSearchParams,
  useShopId,
  useShopView,
  useToken,
} from '../../hooks'
import { useShopError, useShopInfo } from '../../data'
import { formatHotAreaHTML } from '../../utility/utils'
import { reLogin } from '../../utility/request'
import { config } from '../../utility/config'
import './index.css'

type Category = {
  class_id: string
  class_name: string
}

type AdvItem = {
  adv_content: string
}

type AdvData = {
  adv_list: AdvItem[]
  show: String
  seckill: Number
  c_home_modal?: any
  c_home_bottom?: string | null
}

type BlockInfo = {
  flag: number
  image: any
  msg: string | string[]
}

const PAGE_SIZE = 10
const BLOCK_INFO_MAP: Record<number, BlockInfo> = {
  400: {
    flag: 400,
    image: require('../../images/empty/closed.png').default,
    msg: [
      '很抱歉，该店铺暂停营业，无法浏览',
      '您可以到“我的”页面查看订单及售后',
    ],
  }, // 店铺不存在
  411: {
    flag: 411,
    image: require('../../images/empty/upgrade.png').default,
    msg: [
      '店铺升级，请联系店主获取最新店铺链接',
      '您可以到“我的”页面查看订单及售后',
    ],
  }, // 店铺被迁移
  999: {
    flag: 999,
    image: require('../../images/empty/closed.png').default,
    msg: [
      '很抱歉，该店铺暂停营业，无法浏览',
      '您可以到“我的”页面查看订单及售后',
    ],
  }, // 店铺已注销或被拉黑
}

function Home() {
  // 微信登录
  const [Key, code] = useToken()
  const history = useHistory()
  const [searchParams, setSearchParams] = useSearchParams()

  const forceLogin = searchParams.get('force_login') === '1' // 强制登录
  const { shopId, branchId, setShopId } = useShopId({ removeBranchId: true })

  const [paddingTop, setPaddingTop] = useState(0)
  const [qrcodeVisible, setQrcodeVisible] = useState(false)
  const [skinStyle, setSkinStyle] = useState<any>()
  const [searchIcon, setSearchIcon] = useState<string>()

  // 获取店铺信息
  const {
    data: shopInfo,
    error: shopInfoError,
    isLoading: shopInfoLoading,
  } = useShopInfo(!code)
  // 拉黑信息
  const blockInfo = useMemo(
    () => (shopInfoError ? BLOCK_INFO_MAP[shopInfoError.flag] : null),
    [shopInfoError]
  )
  const { mutate: mutateShopError } = useShopError()

  useEffect(() => {
    if (blockInfo) {
      mutateShopError(blockInfo)
    } else if (shopInfo) {
      mutateShopError(undefined)
    }
  }, [shopInfo, blockInfo, mutateShopError])

  // 刷新 ShopID
  const ShopID = shopInfo?.shop_id || ''

  // 获取分类信息
  const {
    data: categories,
    error: categoriesError,
    isLoading: categoriesLoading,
  } = useRequest<Category[]>(
    shopInfo ? { Url: 'Selection.GetClass', ShopID, Key } : null
  )
  const categoryId =
    categories &&
    !categories.some((item) => item.class_id === searchParams.get('cat'))
      ? '24'
      : searchParams.get('cat') || '24'

  // 获取广告信息
  const {
    data: advData,
    error: advDataError,
    isLoading: advDataLoading,
    mutate: mutateAdv,
  } = useRequest<AdvData>(
    shopInfo ? { Url: 'Selection.IndexAdv', ShopID, Key, AdvType: 100 } : null
  )
  const advList = advData?.adv_list.filter((item) => Boolean(item.adv_content))

  // 热卖爆款
  // 店铺开启自动上架才有热卖爆款；只在【首页】显示
  const {
    data: hotsaleList,
    error: hotsaleError,
    isLoading: hotsaleLoading,
  } = useRequest<any[]>(
    Number(shopInfo?.auto_up) === 1 && categoryId === '24'
      ? { Url: 'Selection.HotRecommend', ShopID, Key, PageSize: 6 }
      : null
  )
  const showHotsaleList = hotsaleList?.length === 6 && categoryId === '24'

  // 获取商品列表
  const {
    list: goodsList,
    isLoadingMore,
    isReachingEnd,
    loadMore,
    error: goodsListError,
  } = useRequestInfinite(
    (PageIndex) =>
      shopInfo && categories
        ? {
            Url:
              categoryId === '24'
                ? 'Selection.TodayRecommend'
                : 'Selection.ClassGoods',
            ShopID,
            ClassID: categoryId,
            Key,
            PageSize: PAGE_SIZE,
            PageIndex,
          }
        : null,
    {
      pageSize: PAGE_SIZE,
    }
  )

  // 获取二维码
  const { data: qrcodeUrl, isLoading: qrcodeLoading } = useRequest<string>(
    qrcodeVisible && (ShopID || shopId)
      ? {
          Url: 'Home.GetWxqrcodeHome',
          ShopID: ShopID || shopId,
          BranchID: branchId,
        }
      : null
  )

  useTitle(config.title)
  // PV 请求
  useShopView({ shopId: ShopID, key: Key })

  // 顶部区域 ref
  const headerRef = useCallback(
    (node) => {
      if (node && shopInfo) {
        setPaddingTop(node.getBoundingClientRect().height)
      }
    },
    [shopInfo]
  )

  useEffect(() => {
    if (shopInfoLoading) return

    const wx = window.wx

    // 微信分享
    wx.ready(() => {
      if (shopInfo) {
        const { protocol, host, pathname } = window.location
        const cat = categoryId === '24' ? '' : categoryId
        const shareInfo = {
          title: shopInfo.shop_name
            ? `${shopInfo.shop_name}的店铺`
            : config.title,
          link: `${protocol}//${host}${pathname}?shop_id=${shopInfo.shop_id}&branch_id=${branchId}&cat=${cat}`,
          imgUrl: shopInfo.shop_logo || '',
        }

        wx.showOptionMenu()
        wx.updateAppMessageShareData?.({
          ...shareInfo,
          desc: shopInfo.shop_desc || '欢迎光临~',
        })
        wx.updateTimelineShareData?.(shareInfo)
      }
    })

    if (shopInfo) {
      // 根据接口获取 shop_id 后再更新一遍 localStorage
      setShopId(shopInfo.shop_id)

      // 首页换肤
      if (shopInfo.skins && shopInfo.skins.home) {
        const homeSkin = shopInfo.skins.home

        setSkinStyle({
          '--color': homeSkin.home_top_text_color,
          '--bg-color': homeSkin.shop_section_color,
          '--btn-color': homeSkin.contact_text_color,
          '--btn-bg-color': homeSkin.contact_filled_color,
          '--btn-border-color': homeSkin.contact_border_color,
          '--nav-color': homeSkin.goods_class_text_color,
          '--nav-active-color': homeSkin.goods_class_text_color_selected,
        })

        if (homeSkin.search_icon) {
          setSearchIcon(homeSkin.search_icon)
        }
      }
    }
  }, [shopInfoLoading, shopInfo, setShopId, branchId, categoryId])

  // 触底加载
  useReachEnd(
    () => {
      if (isLoadingMore || isReachingEnd) return

      loadMore()
    },
    { threshold: 250 }
  )

  // 切换商品分类
  function handleCategoryChange(id: string) {
    if (id === categoryId) return

    if (shopInfo) {
      searchParams.set('shop_id', shopInfo.shop_id)
      searchParams.set('branch_id', branchId)
      searchParams.set('cat', id)
      setSearchParams(searchParams)
    }
  }

  // 点击广告
  function handleAdvClick() {
    history.push(`/seckillList?shop_id=${ShopID}`)
  }

  // 强制重新登录
  // 场景：小程序无法获取用户的头像昵称，但是APP代理需要查看顾客用以区分，
  // 此时代理可分享出一个带有参数的二维码，判断C端用户是否需要强制登录，也即首页各区域不能点击
  function onMaskClick() {
    showConfirm('', '您还未登录，是否登录？', () => {
      reLogin(() => {
        history.push(
          `/login?shop_id=${localStorage.getItem(
            'shop_id'
          )}&branch_id=${localStorage.getItem('branch_id')}`
        )
      }, `?shop_id=${ShopID}&branch_id=${branchId}`)
    })
  }

  useErrorResponse(shopInfoError)
  useErrorResponse(categoriesError)
  useErrorResponse(advDataError)
  useErrorResponse(hotsaleError)
  useErrorResponse(goodsListError)

  return (
    <>
      {shopInfoLoading ||
      categoriesLoading ||
      advDataLoading ||
      hotsaleLoading ? (
        <ActivityIndicator toast animating text="稍等哦~" />
      ) : blockInfo ? (
        <Empty
          className="home__empty"
          image={blockInfo.image}
          text={blockInfo.msg}
          showContact={blockInfo.flag === 411}
          shopId={ShopID || shopId}
        />
      ) : (
        <>
          <div
            ref={headerRef}
            className="home__header"
            style={{ ...skinStyle }}
          >
            {/* 店铺信息 */}
            <div className="home__shop">
              <ShopInfo hideRedirect {...shopInfo} />
            </div>

            {/* 分类信息 */}
            <div className="home__nav">
              <Link className="home__search" to="/search">
                <img
                  className="home__search__image"
                  alt=""
                  src={
                    searchIcon ||
                    require('../../images/home_search.png').default
                  }
                />
              </Link>
              {categories && (
                <ul className="home__categories">
                  {[{ class_id: '24', class_name: '首页' }, ...categories].map(
                    ({ class_id, class_name }) => (
                      <li
                        key={class_id}
                        ref={(ref) =>
                          categoryId === class_id && ref?.scrollIntoView()
                        }
                        className={clsx('home__category', {
                          'home__category--active': categoryId === class_id,
                        })}
                        onClick={() => handleCategoryChange(class_id)}
                      >
                        {class_name}
                      </li>
                    )
                  )}
                </ul>
              )}
            </div>
          </div>

          {/* 商品列表 */}
          <div className="home__content" style={{ paddingTop }}>
            {/* 秒杀区域 */}
            {advData?.show === '1' &&
              advData?.seckill === 1 &&
              advList?.[0]?.adv_content && (
                <div className="home__adv" onClick={handleAdvClick}>
                  <img
                    className="home__adv__image"
                    alt=""
                    src={advList[0].adv_content}
                  />
                </div>
              )}

            {/* 热区 */}
            {advData && advData.c_home_bottom && (
              <div className="home__adv--bottom">
                <div
                  dangerouslySetInnerHTML={{
                    __html: formatHotAreaHTML({
                      html: advData.c_home_bottom,
                      parentWidth: Math.min(window.innerWidth - 16, 768 - 16),
                      hrefFormatter: (href) => `${href}&shop_id=${ShopID}`,
                    }),
                  }}
                  onClick={(e) => {
                    e.preventDefault()
                    const element = e.target as HTMLElement

                    // 点击 area 标签区域
                    if (element.tagName === 'AREA') {
                      const { pathname, searchParams } = new URL(
                        element.getAttribute('href') as string
                      )
                      // 拼接小店ID
                      if (localStorage.getItem('branch_id')) {
                        searchParams.set(
                          'branch_id',
                          localStorage.getItem('branch_id') as string
                        )
                      }
                      history.push(`${pathname}?${searchParams}`)
                    }
                  }}
                ></div>
              </div>
            )}

            {/* 弹窗广告 */}
            {advData && advData.c_home_modal && (
              <div className="home__adv--modal">
                <div className="home__adv--modal__body">
                  <Icon
                    className="home__adv--modal__close"
                    type="cross"
                    color="#fff"
                    onClick={() => {
                      mutateAdv(
                        { ...advData, c_home_modal: null },
                        { revalidate: false }
                      )
                    }}
                  />
                  <img
                    src={advData.c_home_modal.adv_content}
                    alt=""
                    onClick={() => {
                      if (advData.c_home_modal.url_type === '2') {
                        const { pathname, search } = new URL(
                          advData.c_home_modal.url_param
                        )
                        mutateAdv(
                          { ...advData, c_home_modal: null },
                          { revalidate: false }
                        )
                        history.push(
                          pathname +
                            search +
                            `&shop_id=${ShopID}&branch_id=${branchId}`
                        )
                      }
                    }}
                  />
                </div>
              </div>
            )}

            {/* 热卖爆款 */}
            {showHotsaleList && (
              <QueueAnim type={['left', 'right']} leaveReverse>
                <div className="section-title">热卖爆款</div>
                <ul key="hotsale__list" className="home__list">
                  {hotsaleList.map((goods: any) => (
                    <GoodsCard key={goods.goods_id} {...goods} />
                  ))}
                </ul>
                <div key="hotsale__status" className="hotsals__more">
                  <Link
                    className="hotsals__more__inner"
                    to={`/hotsale?shop_id=${shopInfo?.shop_id}`}
                  >
                    <span>查看更多</span>
                    <Icon size="xs" type="right" />
                  </Link>
                </div>
              </QueueAnim>
            )}

            {/* 列表区域 */}
            <QueueAnim type={['left', 'right']} leaveReverse>
              {showHotsaleList && <div className="section-title">今日推荐</div>}
              <ul key="home__list" className="home__list">
                {goodsList.map((goods: any) => (
                  <GoodsCard key={goods.goods_id} {...goods} />
                ))}
              </ul>
              <div key="home__status" className="home__status">
                {isLoadingMore && <ActivityIndicator text="加载中..." />}
                {isReachingEnd && '到底咯~'}
              </div>
            </QueueAnim>
          </div>
        </>
      )}

      {/* 回到顶部 */}
      <BackTop bottom={110} />

      {/* 小程序二维码 */}
      <img
        className="open-mini-btn"
        alt="小程序图标"
        src={require('../../images/miniPro.png').default}
        onClick={() => setQrcodeVisible(true)}
      />
      {qrcodeLoading ? (
        <ActivityIndicator toast animating />
      ) : (
        <ShowSimpleContent
          visible={qrcodeVisible}
          animated={false}
          handleCancel={() => setQrcodeVisible(false)}
          formContent={<img src={qrcodeUrl} style={{ width: 220 }} alt="" />}
        />
      )}

      {/* 底部导航栏 */}
      <TabBar />

      {/* 没有登录过，并且带有强制登录标识才显示 mask */}
      {!Key && forceLogin && (
        <div className="home__mask" onClick={onMaskClick}></div>
      )}
    </>
  )
}

export default Home
